
























































































































import Vue from "vue";
export default Vue.extend({
  props: {
    mission: String,
    email: String,
  },
  data:function(){
    return {
      copied: false
    }
  },
  methods: {
    emitClick: function() {
      this.$emit("close", "close");
    },
    copyLink: function(){
      this.copied = true;
      const copyText = this.$refs.link as HTMLInputElement;
       copyText.select();
  copyText.setSelectionRange(0, 99999); /* For mobile devices */

  /* Copy the text inside the text field */
  document.execCommand("copy");
    }
  },
});
