








































import Vue from "vue";
import { mapGetters } from "vuex";
import Popup from "@/components/SharePopup.vue";
export default Vue.extend({
  data: function() {
    return {
      popupActive: false,
      popped: false,
    };
  },

  methods: {
    TogglePopup: function(this: { popupActive: boolean; popped: boolean }) {
      this.popupActive = !this.popupActive;
      this.popped = true;
    },
  },
  computed: {
    ...mapGetters({
      values: "primaryValues",
    }),
    reminders: function() {
      return this.$store.state.reminders;
    },
    mission: function() {
      const values = this.values;
      let shareText = `I just completed my values exercise to find my core values and personal mission statements. My core values are `;
      for (let i = 0; i < values.length; i++) {
        shareText += values[i].value;
        if (values[i + 1]) {
          shareText += ", ";
        }
      }
      return shareText;
    },
    emailLink: function() {
      const values = this.values;

      // let shareText = 'mailto:' + this.$store.state.user.email + '?body=I just completed a values exercise to find my core values and personal mission statements at valuesexercise.com. Ready to give curiosity a try, and identify your own values as well?<br><a href="https://www.valuesexercise.com/" target="_blank">Values Exercise</a>%20-%20<a href="https://www.curiositylab.io" target="_blank">Curiosity Lab</a>&subject=My personal core values are ';
      let shareText =
       ` mailto:${this.$store.state.user.email}?subject=What are your values?&body=I just completed a values exercise to find my core values and personal mission statement at www.valuesexercise.com %0D%0A A My values are `;
      for (let i = 0; i < values.length; i++) {
        shareText += values[i].value;
        if (values[i + 1]) {
          shareText += ", ";
        }
      }
      shareText+=`%0D%0A The Values Exercise comes from Curiosity Lab, creators of Actually Curious, the card game and movement to spread empathy.`
      return shareText;
    },
  },

  components: {
    Popup,
  },
  beforeMount: function(this: { popupActive: boolean; popped: boolean }) {
    setTimeout(() => {
      if (this.popped) {
        return;
      }
      this.popupActive = !this.popupActive;
    }, 10000);
  },
  mounted: function() {
    this.$store.dispatch("SetLogoStyle", { color: "dark" });
    const store = this.$store;
    let reminder;
    for (let i = 0; i < store.state.reminders.length; i++) {
      if (store.state.reminders[i].selected) {
        reminder = store.state.reminders[i];
      }
    }
    const valuesToSend = [];
    for (let i = 0; i < this.values.length; i++) {
      valuesToSend.push(this.values[i].value);
    }
    const obj = {
      email: store.state.user.email,
      name: store.state.user.name,
      lastName: store.state.user.lastName,
      mission: store.state.mission,
      values: valuesToSend,
      reminderInterval: reminder.value.toString(),
      addToNewsLetter: store.state.user.newsletter,
    };
    //https://www.api.valuesexercise.com/send-data
    //http://localhost:3000/send-data           // create env file for dev and prod
    fetch("https://www.api.valuesexercise.com/send-data", {
      method: "POST",
      body: JSON.stringify(obj),
      headers: {
        "Content-Type": "application/json",
      },
    }).then(function(res) {
      console.log(res);
    });
  },
});
